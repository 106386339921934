import Deploy from './deploy'

var configAPI = {}

if(Deploy.produccion){
  configAPI = {
    baseURL : window.location.origin + '/backend/public/api/'
  }
}
else{
  configAPI = {    
    // baseURL : 'https://app.control365.com.ar/backend/public/api/'
    // baseURL : 'https://app.alphasocialmarketing.com/backend/public/api/'          
    // baseURL : 'https://cdsingenieria.inescapp.com/backend/public/api/'
    // baseURL : 'https://laguna.alphasm.com.ar/backend/public/api/'
    // baseURL : 'https://mawen.alphasm.com.ar/backend/public/api/'    
    // baseURL : 'https://admin.titolovende.com.ar/backend/public/api/'
    // baseURL : 'https://prueba.capacsa.com.ar/backend/public/api/'        
    // baseURL : 'https://app.carsoft.com.ar/backend/public/api/'     
    // baseURL : 'https://angelini.alphasm.com.ar/backend/public/api/' 
    // baseURL : 'https://demo.s1.alphasocialmarketing.com/backend/public/api/'               
    // baseURL : 'https://admin.allways.com.ar/backend/public/api/'        
    // baseURL : 'https://app.carsoft.com.ar/backend/public/api/'        
    // baseURL : 'https://lehonor.s1.alphasocialmarketing.com/backend/public/api/'
    // baseURL : 'https://demo.carsoft.com.ar/backend/public/api/'     
    // baseURL : 'https://app.faripack.com.ar/backend/public/api/'
    // baseURL : 'https://patriciabottale.s1.carsoft.com.ar/backend/public/api/'
    // baseURL : 'https://app.tables.com.ar/backend/public/api/'    
    // baseURL : 'https://admin.atlasdistribuciones.com.ar/backend/public/api/'    
    // baseURL : 'https://cid.s1.carsoft.com.ar/backend/public/api/'
    baseURL : 'http://localhost:8000/api/'             
  }
}


export default configAPI;
